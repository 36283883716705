<template>
    <div>
        <div class="text-right">
            <Form inline>
                <FormItem>
                    <Button @click="() => toBeSome()">同步数据</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="() => showMe('新建')">新建</Button>
                </FormItem>
            </Form>
        </div>
        <Table :columns="tableData.title" :data="tableData.list">
            <template slot-scope="{ row, index }" slot="levelName">
                <Tag v-if="row.type != '平台'" size="default" style="transform: scale(0.7) translateY(-6px)" color="blue">
                    {{ row.type }}
                </Tag>
                <Tag v-if="row.type == '平台'" size="default" style="transform: scale(0.7) translateY(-6px)" color="orange">
                    {{ row.type }}
                </Tag>
                {{ row.levelName }}
            </template>
            <template slot-scope="{ row, index }" slot="operate">
                <Button @click="() => showMe('编辑', row)" type="primary" size="small">编辑</Button>
            </template>
            <template slot-scope="{ row, index }" slot="minApp">
                <Tag v-if="row.minApp" type="border" color="success">是</Tag>
                <Tag v-else type="border">否</Tag>
            </template>
            <template slot-scope="{ row, index }" slot="openRec">
                <Tag v-if="row.openRec" type="border" color="success">是</Tag>
                <Tag v-else type="border">否</Tag>
            </template>
            <template slot-scope="{ row, index }" slot="isProportion">
                <Tag v-if="row.isProportion" type="border" color="success">是</Tag>
                <Tag v-else type="border">否</Tag>
            </template>
        </Table>
        <Card class="text-right">
            <Page @on-change="(value) => changePage(value)" :total="tableData.total" :pageSize="tableData.size"></Page>
        </Card>
        <Modal :closable="false" v-model="show" :maskClosable="false" :title="currentModel">
            <Form :showMessage="false" ref="formCreate" :model="formCreate" :rules="formCreateRules" :labelWidth="150">
                <div class="w-96">
                    <FormItem prop="levelName" label="等级名称">
                        <Input :disabled="currentModel == '编辑' || formCreate.type == '晨星'" v-model="formCreate.levelName" />A
                    </FormItem>
                    <FormItem prop="minApp" label="小程序允许开卡">
                        <Checkbox :disabled="formCreate.type == '晨星'" v-model="formCreate.minApp"></Checkbox>
                    </FormItem>
                    <FormItem prop="openRec" label="必须充值开卡">
                        <Checkbox :disabled="formCreate.type == '晨星'" v-model="formCreate.openRec"></Checkbox>
                    </FormItem>
                    <FormItem prop="minRec" label="最低充值金额">
                        <Input :disabled="formCreate.type == '晨星'" placeholder="最低充值金额 0不限制" v-model.number="formCreate.minRec" />
                    </FormItem>
                    <!--<FormItem prop="eNum" label="每天次限购折扣票数">
                        <Input :disabled="formCreate.type == '晨星'" placeholder="每天次限购折扣票数 0不限制" v-model.number="formCreate.eNum" />
                    </FormItem>
                    <FormItem prop="sNum" label="每场次限购折扣票数">
                        <Input :disabled="formCreate.type == '晨星'" placeholder="每场次限购折扣票数 0不限制" v-model.number="formCreate.sNum" />
                    </FormItem>-->
                    <FormItem prop="isProportion" label="是否积分卡">
                        <Checkbox
                            :disabled="formCreate.type == '晨星'"
                            @on-change="(value) => jfcheckBoxChange(value)"
                            v-model="formCreate.isProportion"
                        ></Checkbox>
                    </FormItem>
                    <FormItem v-if="formCreate.isProportion" prop="ticketProportion" label="购票积分获取比例">
                        <Input :disabled="formCreate.type == '晨星'" v-model.number="formCreate.ticketProportion" />
                    </FormItem>
                    <FormItem v-if="formCreate.isProportion" prop="merProportion" label="卖品积分获取比例">
                        <Input :disabled="formCreate.type == '晨星'" v-model.number="formCreate.merProportion" />
                    </FormItem>
                    <FormItem prop="banPay" label="禁止交易">
                        <i-Switch v-model="formCreate.banPay"></i-Switch>
                    </FormItem>
                    <FormItem prop="banbindingField" label="禁用绑定字段">
                        <Select multiple v-model="formCreate.banbindingField">
                            <Option :key="index" v-for="(item, index) in banbindingFieldConfig" :value="item.value">{{ item.label }}</Option>
                        </Select>
                    </FormItem>
                </div>
            </Form>
            <div slot="footer">
                <Button @click="() => hideMe()">取消</Button>
                <Button @click="() => submit()" type="primary">确认</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { GetLvGroupList, LvGroup, GetPresetValue } from '@/request/api';
export default {
    name: 'memberLevel',
    data() {
        return {
            tableData: '',
            show: false,
            currentModel: '',
            formCreateRules: {
                levelName: { required: true, trigger: 'blur' },
                minApp: { required: true, trigger: 'blur', type: 'boolean' },
                openRec: { required: true, trigger: 'blur', type: 'boolean' },
                minRec: { required: true, trigger: 'blur', type: 'number', min: 0 },
                eNum: { required: true, trigger: 'blur', type: 'number', min: 0 },
                sNum: { required: true, trigger: 'blur', type: 'number', min: 0 },
                isProportion: { required: true, trigger: 'blur', type: 'boolean' },
                banPay: { required: true, trigger: 'blur', type: 'boolean' },
                banbindingField: { trigger: 'blur', type: 'array' },
            },
            formCreate: {
                levelName: '',
                minApp: false,
                openRec: false,
                minRec: 0,
                eNum: 0,
                sNum: 0,
                isProportion: false,
                ticketProportion: 0.1,
                merProportion: 0.1,
                banPay: false,
                banbindingField: [],
            },
            tableRowData: '',
            banbindingFieldConfig: [], //禁用绑定字段配置
        };
    },
    mounted() {
        this.fetchTableData();
    },
    methods: {
        /**
         * 获取禁用绑定字段配置
         */
        async handlebanbindingFieldConfig() {
            let res = await GetPresetValue({ type: '绑定限制字段' });
            if (res.errcode != 0) return;
            this.banbindingFieldConfig = res.data;
        },
        /**
         * 积分勾选框
         */
        jfcheckBoxChange(value) {
            if (value) {
                this.formCreateRules = {
                    ...this.formCreateRules,
                    ticketProportion: { required: true, trigger: 'blur', type: 'number', min: 0.01, max: 10 },
                    merProportion: { required: true, trigger: 'blur', type: 'number', min: 0.01, max: 10 },
                };
            } else {
                delete this.formCreateRules['ticketProportion'];
                delete this.formCreateRules['merProportion'];
            }
        },
        /**
         * 弹窗确认按钮
         */
        async submit() {
            let currentModel = this.currentModel;
            let result = await this.handleSumit('formCreate');
            switch (currentModel) {
                case '新建':
                    if (result) {
                        await this.create();
                    }
                    break;
                case '编辑':
                    if (result) {
                        await this.create();
                    }
                    break;
            }
        },

        hideMe() {
            this.handleReset('formCreate');
            this.show = false;
        },
        /**
         * 新建/编辑确认点击
         */
        async create() {
            if (this.currentModel == '新建') {
                let passdata = {
                    type: '新建',
                    newData: JSON.parse(JSON.stringify(this.formCreate)),
                };
                if (!this.formCreate.isProportion) {
                    delete passdata['newData'].ticketProportion;
                    delete passdata['newData'].merProportion;
                }
                let res = await LvGroup(passdata);
                if (res.errcode != 0) return;
            }
            if (this.currentModel == '编辑') {
                const { _id } = this.tableRowData;
                let passdata = {
                    ID: _id,
                    type: '编辑',
                    editData: JSON.parse(JSON.stringify(this.formCreate)),
                };
                if (!this.formCreate.isProportion) {
                    delete passdata['editData'].ticketProportion;
                    delete passdata['editData'].merProportion;
                }
                delete passdata['editData'].levelName;
                let res = await LvGroup(passdata);
                if (res.errcode != 0) return;
            }
            this.fetchTableData(this.tableData.page);
            this.hideMe();
        },
        /**
         * 弹窗 新建/编辑
         */
        async showMe(type, row) {
            this.handlebanbindingFieldConfig();

            switch (type) {
                case '新建':
                    this.currentModel = '新建';
                    break;
                case '编辑':
                    this.currentModel = '编辑';
                    const { ID } = row;
                    let res = await LvGroup({
                        type: '获取数据',
                        ID,
                    });
                    if (res.errcode != 0) return;
                    this.tableRowData = res.data;
                    const { type, levelName, minApp, openRec, minRec, eNum, sNum, isProportion, ticketProportion, merProportion, banPay, banbindingField } =
                        res.data;
                    this.formCreate = {
                        type,
                        levelName,
                        minApp,
                        openRec,
                        minRec,
                        eNum,
                        sNum,
                        isProportion,
                        ticketProportion,
                        merProportion,
                        banbindingField,
                        banPay,
                    };
                    break;
            }
            this.show = true;
        },
        /**
         * 同步数据按钮
         */
        async toBeSome() {
            let res = await LvGroup({ type: '同步' });
            if (res.errcode != 0) return;
            this.$Message.success('同步成功');
            this.fetchTableData();
        },
        changePage(value) {
            this.fetchTableData(value);
        },
        async fetchTableData(page = 1) {
            let res = await GetLvGroupList({
                page,
                all: false,
            });
            if (res.errcode != 0) return;
            res.data.title.forEach((item) => {
                if (item.key == 'minApp') {
                    item['slot'] = 'minApp';
                }
                if (item.key == 'openRec') {
                    item['slot'] = 'openRec';
                }
                if (item.key == 'isProportion') {
                    item['slot'] = 'isProportion';
                }
                if (item.key == 'levelName') {
                    item['slot'] = 'levelName';
                }
            });
            res.data.title.push({
                key: 'operate',
                title: '操作',
                slot: 'operate',
            });
            this.tableData = res.data;
        },
        handleSumit(name) {
            return new Promise((resolve) => {
                this.$refs[name].validate((valid) => {
                    resolve(valid);
                });
            });
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
    },
};
</script>
