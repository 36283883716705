<template>
    <div>
        <div style="padding: 20px; text-align: right">
            <Button @click="() => create()" type="primary">新增</Button>
        </div>
        <Table :columns="tableData.title" :data="tableData.list">
            <template slot-scope="{ row, index }" slot="operation">
                <Button type="primary" size="small" style="margin-right: 5px" @click="() => view(row)">查看</Button>
                <Button size="small" style="margin-right: 5px" @click="() => edit(row)">编辑</Button>
                <Poptip transfer confirm title="确定删除该条记录?" @on-ok="() => remove(row)">
                    <Button type="error" size="small">删除</Button>
                </Poptip>
            </template>
            <template slot-scope="{ row, index }" slot="infoV">
                <div style="color: orange">
                    <div>会员价：{{ row.memberPrice }}</div>
                    <div>普通价格：{{ row.baseSellPrice }}</div>
                </div>
            </template>
            <template slot-scope="{ row, index }" slot="longTerm">
                <div v-if="row.longTerm">长期有效</div>
                <div v-else>{{ row.startDate }}-{{ row.endDate }}</div>
            </template>
            <template slot-scope="{ row, index }" slot="enableStatus">
                <div v-if="row.enableStatus" style="color: green">有效</div>
                <div v-else style="color: red">无效</div>
            </template>
        </Table>
        <Modal
            width="800"
            :closable="false"
            :mask-closable="false"
            v-model="modalEdit"
            :title="
                ((modalType.edit == modalActiveIndex && '编辑') ||
                    (modalType.view == modalActiveIndex && '查看') ||
                    (modalType.create == modalActiveIndex && '新增')) + (modalType.create != modalActiveIndex ? currentRowData.name : '')
            "
        >
            <Tabs v-model="activeTab" @on-click="changeTab" type="card">
                <TabPane label="基础设置">
                    <div style="width: 800px">
                        <Form
                            :disabled="modalType.view == modalActiveIndex"
                            :showMessage="false"
                            ref="formValidate"
                            :model="formValidate"
                            :rules="ruleValidate"
                            :label-width="100"
                        >
                            <div style="display: flex; width: 400px">
                                <FormItem label="方案名称" prop="name">
                                    <Input v-model="formValidate.name"></Input>
                                </FormItem>
                                <FormItem style="margin-left: 10px" :label-width="0" label="" prop="enableStatus">
                                    <i-Switch v-model="formValidate.enableStatus" />
                                    <span style="padding-left: 5px">启用方案</span>
                                </FormItem>
                            </div>
                            <FormItem label="类型" prop="infoTyps">
                                <Select :disabled="modalActiveIndex == modalType.edit" transfer v-model="formValidate.infoTyps" style="width: 250px">
                                    <Option v-for="item in infoTypsSelectList" :value="item.value" :key="item.value">
                                        {{ item.label }}
                                    </Option>
                                </Select>
                            </FormItem>
                            <FormItem label="有效时间" prop="longTerm">
                                <Select transfer v-model="formValidate.longTerm" style="width: 250px">
                                    <Option v-for="item in longTermSelectList" :value="item.value" :key="item.value">
                                        {{ item.label }}
                                    </Option>
                                </Select>
                            </FormItem>
                            <div v-if="formValidate.longTerm == 'false'" style="display: flex">
                                <div style="width: 100px; text-align: right; padding-right: 12px; display: flex; justify-content: center; align-items: center">
                                    <div>有效期</div>
                                </div>
                                <div style="flex: 1; display: flex">
                                    <FormItem :label-width="0" label="" prop="startDate">
                                        <DatePicker
                                            @on-change="(value) => (formValidate.startDate = value)"
                                            :value="formValidate.startDate"
                                            transfer
                                            type="date"
                                            placeholder="开始时间"
                                            style="width: 120px"
                                        ></DatePicker>
                                    </FormItem>
                                    <FormItem style="margin-left: 10px" :label-width="0" label="" prop="endDate">
                                        <DatePicker
                                            @on-change="(value) => (formValidate.endDate = value)"
                                            :value="formValidate.endDate"
                                            transfer
                                            type="date"
                                            placeholder="结束时间"
                                            style="width: 120px"
                                        ></DatePicker>
                                    </FormItem>
                                </div>
                            </div>
                            <FormItem label="星期范围" prop="week">
                                <Select placeholder="每天" multiple transfer v-model="formValidate.week" style="width: 250px">
                                    <Option v-for="item in weekSelectList" :value="item.value" :key="item.value">
                                        {{ item.label }}
                                    </Option>
                                </Select>
                            </FormItem>
                            <FormItem label="时段范围">
                                <Select @on-change="(value) => timeRangChange(value)" transfer v-model="timeSelectValue" style="width: 250px">
                                    <Option v-for="item in timeSelectList" :value="item.value" :key="item.value">
                                        {{ item.label }}
                                    </Option>
                                </Select>
                                <Button
                                    v-if="timeSelectValue == '自定义'"
                                    @click="() => timeRangePush()"
                                    style="margin-left: 10px"
                                    type="primary"
                                    icon="md-add"
                                ></Button>
                            </FormItem>
                            <template v-for="(item, index) in formValidate.time">
                                <div v-if="timeSelectValue == '自定义'" :key="index" style="display: flex">
                                    <div
                                        style="
                                            width: 100px;
                                            text-align: right;
                                            padding-right: 12px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                        "
                                    >
                                        <div>范围</div>
                                    </div>
                                    <div style="display: flex">
                                        <FormItem :label-width="0" label="" prop="startDate">
                                            <TimePicker
                                                :value="formValidate.time[index].start"
                                                @on-change="(value) => (formValidate.time[index].start = value)"
                                                transfer
                                                type="time"
                                                placeholder="开始时间"
                                                style="width: 120px"
                                            ></TimePicker>
                                        </FormItem>
                                        <FormItem style="margin-left: 10px" :label-width="0" label="" prop="endDate">
                                            <TimePicker
                                                :value="formValidate.time[index].end"
                                                @on-change="(value) => (formValidate.time[index].end = value)"
                                                transfer
                                                type="time"
                                                placeholder="结束时间"
                                                style="width: 120px"
                                            ></TimePicker>
                                        </FormItem>
                                    </div>
                                    <Button
                                        @click="() => timeRangeRemove(index)"
                                        v-if="timeSelectValue == '自定义'"
                                        style="margin-left: 10px"
                                        type="error"
                                        icon="md-remove"
                                    ></Button>
                                </div>
                            </template>
                            <FormItem label="适用发行价" prop="minPrice">
                                <Select placeholder="适用全部" multiple transfer v-model="formValidate.minPrice" style="width: 250px">
                                    <Option v-for="item in minPriceSelectList" :value="item.value" :key="item.value">
                                        {{ item.label }}
                                    </Option>
                                </Select>
                            </FormItem>
                            <FormItem label="适用影厅" prop="minPrice">
                                <Select placeholder="适用全部" multiple transfer v-model="formValidate.screen" style="width: 250px">
                                    <Option v-for="item in screenSelectList" :value="item.value" :key="item.value">
                                        {{ item.label }}
                                    </Option>
                                </Select>
                            </FormItem>
                            <div style="display: flex; width: 400px">
                                <div style="width: 100px; padding-right: 12px; display: flex; justify-content: right; align-items: center">
                                    <div>会员价格</div>
                                </div>
                                <FormItem :label-width="0">
                                    <Select
                                        class="mr-2"
                                        @on-change="(value) => handleMemeberPriceSelect(value)"
                                        transfer
                                        v-model="memberPriceSelectValue"
                                        style="width: 120px"
                                    >
                                        <Option v-for="item in baseSellPriceSelectList" :value="item.value" :key="item.value">
                                            {{ item.label }}
                                        </Option>
                                    </Select>
                                </FormItem>
                                <FormItem :label-width="0" style="width: 120px" label="" prop="memberPrice">
                                    <Input v-if="memberPriceSelectValue == '自定义'" v-model="formValidate.memberPrice"></Input>
                                </FormItem>
                            </div>
                            <div style="display: flex; width: 400px">
                                <div style="width: 100px; padding-right: 12px; display: flex; justify-content: right; align-items: center">
                                    <div>普通价格</div>
                                </div>
                                <FormItem :label-width="0">
                                    <Select
                                        @on-change="(value) => handleBaseSellPriceSelect(value)"
                                        transfer
                                        v-model="baseSellPriceSelectValue"
                                        style="width: 120px"
                                    >
                                        <Option v-for="item in baseSellPriceSelectList" :value="item.value" :key="item.value">
                                            {{ item.label }}
                                        </Option>
                                    </Select>
                                </FormItem>
                                <FormItem :label-width="0" style="width: 120px; margin-left: 10px" label="" prop="baseSellPrice">
                                    <Input v-show="baseSellPriceSelectValue == '自定义'" v-model="formValidate.baseSellPrice"></Input>
                                </FormItem>
                            </div>
                            <FormItem label="优先级" prop="priority">
                                <Input type="number" style="width: 120px" v-model.number="formValidate.priority"></Input>
                            </FormItem>
                        </Form>
                    </div>
                </TabPane>
                <TabPane label="影片范围">
                    <!--<Transfer :filterable="true" :data="data1" :target-keys="targetKeys1" :render-format="render1" @on-change="handleChange1"></Transfer>-->
                    <Alert show-icon>输入影片名称，搜索影片</Alert>
                    <Form>
                        <FormItem>
                            <Select placeholder="请输入片名" v-model="model14" multiple filterable :remote-method="remoteMethod2" :loading="loading2">
                                <Option v-for="(option, index) in options2" :value="option.value" :key="index">{{ option.label }}</Option>
                            </Select>
                        </FormItem>
                    </Form>
                </TabPane>
                <TabPane label="会员策略">
                    <Form :labelWidth="100" ref="formPolice" :rules="rulePolice" :model="formPolice">
                        <FormItem label="会员卡等级" prop="levelCode">
                            <Select v-model="formPolice.levelCode" style="width: 260px">
                                <Option v-for="item in levelDirctory" :value="item.value" :key="item.value">{{ item.label }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="优惠类型" prop="preferenceType">
                            <Select @on-change="(value) => changeCutType(value)" v-model="formPolice.preferenceType" style="width: 260px">
                                <Option v-for="item in cutDirctor" :value="item.value" :key="item.value">{{ item.label }}</Option>
                            </Select>
                        </FormItem>

                        <FormItem labelPosition="left" label="优惠金额" prop="preferenceValue">
                            <InputNumber :min="0" v-model="formPolice.preferenceValue" style="width: 200px" />
                        </FormItem>

                        <div class="flex">
                            <FormItem labelPosition="left" label="当日限购量" prop="purchaseNum">
                                <InputNumber :min="0" v-model="formPolice.purchaseNum" style="width: 200px" />
                            </FormItem>
                            <FormItem>
                                <Button @click="() => saveToTable()" class="ml-7" type="primary">添加</Button>
                            </FormItem>
                        </div>
                    </Form>
                    <Table size="small" :columns="mTitle" :data="mList">
                        <template slot-scope="{ row, index }" slot="purchaseNum">
                            <div>{{ row.purchaseNum > 0 ? row.purchaseNum : '不限' }}</div>
                        </template>
                        <template slot-scope="{ row, index }" slot="operate">
                            <Button @click="() => removeTabelRow(index)" size="small" type="error">删除</Button>
                        </template>
                    </Table>
                </TabPane>
                <TabPane name="影厅分区" label="影厅分区">
                    <Form :labelWidth="100" ref="tingdata" :rules="ruleTing" :model="tingdata">
                        <FormItem label="影厅编码" prop="screenCode">
                            <Cascader @on-change="handleCascaderChange" :data="tingConfigList" v-model="tingdata.screenCode" style="width: 200px"></Cascader>
                        </FormItem>
                        <FormItem label="影厅版本" prop="version">
                            <Input disabled v-model="tingdata.version" style="width: 200px" />
                        </FormItem>
                        <FormItem label="座位颜色" prop="colour">
                            <ColorPicker v-model="tingdata.colour" />
                        </FormItem>

                        <FormItem label="分区座位等级显示名称" prop="levelName">
                            <Input v-model="tingdata.levelName" style="width: 200px" />
                        </FormItem>

                        <div style="display: flex">
                            <FormItem label="普通价策略" prop="baseSellPrice.preferenceType">
                                <Select v-model="tingdata.baseSellPrice.preferenceType" style="width: 260px">
                                    <Option v-for="item in cutDirctor" :value="item.value" :key="item.value">{{ item.label }}</Option>
                                </Select>
                            </FormItem>
                            <FormItem :labelWidth="10" labelPosition="left" label="" prop="baseSellPrice.preferenceValue">
                                <Input placeholder="金额" v-model.number="tingdata.baseSellPrice.preferenceValue" style="width: 200px" />
                            </FormItem>
                        </div>
                        <div style="display: flex">
                            <FormItem label="会员价策略" prop="memberPrice.preferenceType">
                                <Select v-model="tingdata.memberPrice.preferenceType" style="width: 260px">
                                    <Option v-for="item in cutDirctor" :value="item.value" :key="item.value">{{ item.label }}</Option>
                                </Select>
                            </FormItem>
                            <FormItem :labelWidth="10" labelPosition="left" label="" prop="memberPrice.preferenceValue">
                                <Input placeholder="金额" v-model.number="tingdata.memberPrice.preferenceValue" style="width: 200px" />
                            </FormItem>
                        </div>
                        <div style="display: flex; justify-content: end">
                            <FormItem>
                                <Button @click="saveToTingTable" class="ml-7" type="primary">添加</Button>
                            </FormItem>
                        </div>
                    </Form>
                    <Table size="small" :columns="tingTitle" :data="formValidate.screenSeatlv">
                        <template slot-scope="{ row, index }" slot="colour">
                            <div :style="`width: 40px;height: 15px;background-color:${row.colour};border:1px solid #ccc`"></div>
                        </template>
                        <template slot-scope="{ row, index }" slot="operate">
                            <div>{{ row.baseSellPrice }}</div>
                        </template>

                        <template slot-scope="{ row, index }" slot="baseSellPrice">
                            <div>{{ row.baseSellPrice.preferenceType }}:{{ row.baseSellPrice.preferenceValue }}</div>
                        </template>
                        <template slot-scope="{ row, index }" slot="memberPrice">
                            <div>{{ row.memberPrice.preferenceType }}:{{ row.memberPrice.preferenceValue }}</div>
                        </template>
                        <template slot-scope="{ row, index }" slot="operate">
                            <Button @click="() => removeTingTabelRow(index)" size="small" type="error">删除</Button>
                        </template>
                    </Table>
                </TabPane>
            </Tabs>
            <div slot="footer">
                <div>
                    <Button @click="() => cancel()">取消</Button>
                    <Button v-show="modalActiveIndex != modalType.view" style="margin-left: 10px" @click="() => ok()" type="primary">确认</Button>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import { PriceInfo, SeatInfoUpDate, GetLvGroupList, GetFilmList } from '@/request/api';
import axios from 'axios';
export default {
    name: 'pricing_strategy_v2',
    data() {
        return {
            cutDirctor: [
                {
                    label: '立减金额',
                    value: '立减金额',
                },
                {
                    label: '立减折扣',
                    value: '立减折扣',
                },
                {
                    label: '固定金额',
                    value: '固定金额',
                },
            ],
            rulePolice: {
                levelCode: { required: false, trigger: 'blur', showMessage: false },
                preferenceType: { required: false, trigger: 'blur', showMessage: false },
                preferenceValue: { required: false, trigger: 'blur', showMessage: false, min: 0.01, type: 'number' },
            },
            formPolice: {
                levelCode: '',
                preferenceType: '',
                preferenceValue: 0,
                purchaseNum: 0,
            },
            mTitle: [
                {
                    title: '等级编号',
                    key: 'levelCode',
                },
                {
                    title: '卡等级',
                    key: 'levelName',
                },
                {
                    title: '优惠类型',
                    key: 'preferenceType',
                },
                {
                    title: '优惠金额',
                    key: 'preferenceValue',
                },
                {
                    title: '当日限购量',
                    key: 'purchaseNum',
                    slot: 'purchaseNum',
                },
                {
                    title: '操作',
                    key: 'operate',
                    slot: 'operate',
                },
            ],
            mList: [],
            modalActiveIndex: 0,
            modalType: {
                view: 0,
                edit: 1,
                create: 2,
            },
            data1: [],
            targetKeys1: [],
            memberPriceSelectValue: '',
            baseSellPriceSelectValue: '',
            baseSellPriceSelectList: [
                {
                    value: '系统价',
                    label: '系统价',
                },
                {
                    value: '发行价',
                    label: '发行价',
                },
                {
                    value: '标准价',
                    label: '标准价',
                },
                {
                    value: '自定义',
                    label: '自定义',
                },
            ],
            screenSelectList: [],
            minPriceSelectList: [
                {
                    value: '15',
                    label: '15',
                },
                {
                    value: '20',
                    label: '20',
                },
                {
                    value: '25',
                    label: '25',
                },
                {
                    value: '30',
                    label: '30',
                },
                {
                    value: '35',
                    label: '35',
                },
                {
                    value: '40',
                    label: '40',
                },
                {
                    value: '45',
                    label: '45',
                },
                {
                    value: '50',
                    label: '50',
                },
                {
                    value: '50',
                    label: '50',
                },
                {
                    value: '55',
                    label: '55',
                },
                {
                    value: '60',
                    label: '60',
                },
            ],
            timeSelectValue: '',
            timeSelectList: [
                {
                    value: '全时段范围',
                    label: '全时段范围',
                },
                {
                    value: '自定义',
                    label: '自定义',
                },
            ],
            weekSelectList: [
                {
                    value: '周一',
                    label: '周一',
                },
                {
                    value: '周二',
                    label: '周二',
                },
                {
                    value: '周三',
                    label: '周三',
                },
                {
                    value: '周四',
                    label: '周四',
                },
                {
                    value: '周五',
                    label: '周五',
                },
                {
                    value: '周六',
                    label: '周六',
                },
                {
                    value: '周日',
                    label: '周日',
                },
            ],
            longTermSelectList: [
                {
                    value: 'true',
                    label: '长期有效',
                },
                {
                    value: 'false',
                    label: '自定义',
                },
            ],
            infoTypsSelectList: [
                {
                    value: '基础策略',
                    label: '基础策略',
                },
                {
                    value: '附加策略',
                    label: '附加策略',
                },
            ],
            tableData: '',
            modalEdit: false,
            currentRowData: '',
            formValidate: {
                name: '',
                infoTyps: '',
                enableStatus: false,
                screen: [],
                longTerm: '',
                startDate: '',
                endDate: '',
                week: [],
                time: [],
                movie: [],
                minPrice: [],
                priority: 1,
                baseSellPrice: '',
                memberPrice: '',
                activity: [],
                screenSeatlv: [],
            },
            formValidateCopy: {},
            ruleValidate: {
                name: {
                    required: true,
                    trigger: 'blur',
                },
                infoTyps: {
                    required: true,
                    trigger: 'blur',
                },
                longTerm: {
                    required: true,
                    trigger: 'blur',
                },
                week: {
                    required: true,
                    type: 'array',
                    trigger: 'blur',
                },
                priority: {
                    type: 'number',
                    required: true,
                    trigger: 'blur',
                },
            },
            levelDirctory: [],
            loading2: false,
            options2: [],
            model14: [],
            tingConfigList: [],
            tingdata: {
                screenCode: [], //影厅编码
                screenName: '', //影厅名称
                version: '', //记录设置此方案时的影厅版本
                colour: '', //座位颜色
                levelCode: '', //分区座位等级编号
                levelName: '', //分区座位等级显示名称
                baseSellPrice: {
                    preferenceType: '', //处理类型
                    preferenceValue: null, //处理值
                }, //普通价格处理
                memberPrice: {
                    preferenceType: '', //处理类型 ["立减金额"|"立减折扣"|"固定金额"]三选一
                    preferenceValue: null, //处理值
                }, //会员价格处理
            },
            tingTitle: [
                {
                    title: '影厅名称',
                    key: 'screenName',
                },
                {
                    title: '座位颜色',
                    key: 'colour',
                    slot: 'colour',
                },
                {
                    title: '分区等级',
                    key: 'levelCode',
                },
                {
                    title: '分区名称',
                    key: 'levelName',
                },
                {
                    title: '影厅版本',
                    key: 'version',
                },
                {
                    title: '普通价格',
                    key: 'baseSellPrice',
                    slot: 'baseSellPrice',
                    width: 130,
                },
                {
                    title: '会员价格',
                    key: 'memberPrice',
                    slot: 'memberPrice',
                    width: 130,
                },
                {
                    title: '操作',
                    key: 'operate',
                    slot: 'operate',
                },
            ],
            ruleTing: {
                screenCode: { required: true, trigger: 'blur', showMessage: false },
            },
            activeTab: 0,
        };
    },
    async mounted() {
        this.getLevelDirctory();
        this.formValidateCopy = JSON.parse(JSON.stringify(this.formValidate));
        this.handlePriceInfo('获取列表');
        this.handleSeatInfoUpDate();
        let res = await GetFilmList({});
        if (res.errcode != 0) return;
        this.data1 = res.data;
        this.data1.forEach((item, index) => {
            item['key'] = index + '';
            item['label'] = item.movieName;
        });
    },
    watch: {
        modalEdit(value) {
            if (!value) {
                this.timeSelectValue = '';
                this.activeTab = 0;
                this.handleReset('tingdata');
            }
        },
    },
    methods: {
        handleCascaderChange(value, selectedData) {
            let { screenCode, screenName, Version } = selectedData[0];
            let { colour, level, levelName } = selectedData[1];
            this.tingdata = {
                screenCode: screenCode, //影厅编码
                screenName: screenName, //影厅名称
                version: Version, //记录设置此方案时的影厅版本
                colour: colour, //座位颜色
                levelCode: level, //分区座位等级编号
                levelName: levelName, //分区座位等级显示名称
                baseSellPrice: {
                    preferenceType: '', //处理类型
                    preferenceValue: null, //处理值
                }, //普通价格处理
                memberPrice: {
                    preferenceType: '', //处理类型 ["立减金额"|"立减折扣"|"固定金额"]三选一
                    preferenceValue: null, //处理值
                }, //会员价格处理
            };
        },
        /**
         * 切换页签
         */
        async changeTab(name) {
            if (name == '影厅分区') {
                let list = await this.handleSeatInfoUpDate(true);
                list.forEach((item) => {
                    item['label'] = item.screenName;
                    item['value'] = item.screenCode;
                    item['children'] = item.levelList.map((item1) => {
                        item1['label'] = item1.levelName;
                        item1['value'] = item1.level;
                        item1['disabled'] = item1.disable || item1.default;

                        return item1;
                    });
                });
                this.tingConfigList = list;
            }
        },
        remoteMethod2(query) {
            if (query !== '') {
                this.loading2 = true;
                setTimeout(async () => {
                    this.loading2 = false;
                    let passdata = {
                        year: new Date().getFullYear() + '',
                        name: query,
                    };
                    const instance = axios.create({
                        baseURL: 'https://gzxh.ruiziyou.com/conf/film/queryFilmsOption',
                        timeout: 5000,
                        method: 'POST',
                    });
                    let res = await instance.post('', passdata);
                    let list = res.data.data;
                    list = list.filter((item) => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
                    for (let i = 0; i < list.length; i++) {
                        let count = this.options2.filter((item) => item.value.toLowerCase().indexOf(list[i].value.toLowerCase()) == -1);

                        //没有的数据筛入配置项里面
                        if (count) {
                            this.options2.push(list[i]);
                        }
                    }
                }, 200);
            } else {
                this.options2 = [];
            }
        },
        /**
         * 时段范围切换
         */
        timeRangChange(value) {
            if (value == '自定义') {
                this.formValidate.memberPrice = '';
            }
        },
        /**
         * 切换优惠类型
         */
        changeCutType(value) {
            this.rulePolice['preferenceValue'] = {
                required: true,
                trigger: 'blur',
                showMessage: false,
                min: 0.1,
                type: 'number',
            };

            if (value == '立减折扣') {
                this.rulePolice['preferenceValue'] = {
                    required: true,
                    trigger: 'blur',
                    showMessage: false,
                    min: 0.01,
                    max: 1,
                    type: 'number',
                };
            }
        },
        removeTingTabelRow(index) {
            this.formValidate.screenSeatlv.splice(index, 1);
        },
        /**
         * 会员策略表格行 数据移除
         */
        removeTabelRow(index) {
            this.mList.splice(index, 1);
        },
        /**
         * 设置到影厅分区
         */
        saveToTingTable() {
            this.$refs['tingdata'].validate((valid) => {
                if (valid) {
                    let data = JSON.parse(JSON.stringify(this.tingdata));
                    if (this.formValidate.screenSeatlv) {
                        let levelCodeExsitList = this.formValidate.screenSeatlv.map((item) => item.screenName + item.levelCode);
                        let levelCodeNow = data.screenName + data.levelCode;
                        if (levelCodeExsitList.indexOf(levelCodeNow) != -1) {
                            this.$Message.warning('不能重复添加分区等级');
                            return;
                        }
                        this.formValidate.screenSeatlv.push(data);
                    } else {
                        this.formValidate.screenSeatlv = [data];
                        this.formValidate = JSON.parse(JSON.stringify(this.formValidate));
                    }
                }
            });
        },
        /**
         * 设置会员策略 到 表格
         */
        saveToTable() {
            this.$refs['formPolice'].validate((valid) => {
                if (valid) {
                    const { levelCode, preferenceType, preferenceValue, purchaseNum } = this.formPolice;
                    const { levelName } = this.levelDirctory.filter((item, index) => item.levelCode == levelCode)[0];
                    debugger;
                    this.mList.push({
                        levelCode,
                        levelName,
                        preferenceType,
                        preferenceValue,
                        purchaseNum: purchaseNum,
                    });
                    console.log(this.mList);
                    debugger;
                }
            });
        },
        async getLevelDirctory() {
            let res = await GetLvGroupList({ all: true });
            if (res.errcode != 0) return;
            res.data.list.forEach((item, index) => {
                item['label'] = item.levelName;
                item['value'] = item.levelCode;
            });
            this.levelDirctory = res.data.list;
        },
        render1(item) {
            return item.key + ':' + item.label;
        },
        handleChange1(newTargetKeys) {
            console.log(newTargetKeys, '==newTargetKeys');
            this.targetKeys1 = newTargetKeys;
        },
        handleMemeberPriceSelect(value) {
            if (value == '自定义') {
                this.formValidate.memberPrice = '';
            } else {
                this.formValidate.memberPrice = value;
            }
        },
        handleBaseSellPriceSelect(value) {
            if (value == '自定义') {
                this.formValidate.baseSellPrice = '';
            } else {
                this.formValidate.baseSellPrice = value;
            }
        },
        /**
         * 影厅字典获取
         */
        async handleSeatInfoUpDate(databack) {
            let res = await SeatInfoUpDate({
                type: '获取列表',
            });
            if (res.errcode != 0) return;
            if (databack) {
                return JSON.parse(JSON.stringify(res.data.list));
            }
            for (let i = 0; i < res.data.list.length; i++) {
                const { screenCode, screenName } = res.data.list[i];
                res.data.list[i] = {
                    value: screenCode,
                    label: screenName,
                };
            }
            this.screenSelectList = res.data.list;
        },
        /**
         * 移除时段范围
         */
        timeRangeRemove(value) {
            this.formValidate.time = this.formValidate.time.filter((item, index) => index != value);
        },
        /**
         * 插入时段范围
         */
        timeRangePush() {
            if (this.formValidate.time.length >= 5) {
                this.$Message.warning('数量不能超过5个');
                return;
            }
            this.formValidate.time.push({
                start: '',
                end: '',
            });
        },
        handleSubmit(name) {
            if (this.timeSelectValue == '自定义' && this.formValidate.time.length <= 0) {
                this.$Message.warning('请添加时段范围');
                return;
            }
            if (this.timeSelectValue == '全时段范围') {
                this.formValidate.time = [];
            }
            this.$refs[name].validate(async (valid) => {
                if (valid) {
                    const { user } = JSON.parse(localStorage.getItem('选中品牌1'));
                    const { _id } = this.formValidate;
                    let movie = this.data1.filter((item, index) => this.targetKeys1.indexOf(index + '') != -1) || [];
                    this.formValidate.movie = movie;
                    this.formValidate.time =
                        (this.formValidate.time && this.formValidate.time.filter((item, index) => item.end != '' && item.start != '')) || [];
                    let type;
                    let passdata = {
                        type: type,
                        id: _id,
                        modifier: user.name,
                        Pricedata: {
                            ...this.formValidate,
                        },
                    };
                    if (this.modalActiveIndex == this.modalType.edit) {
                        passdata.type = '修改编辑';
                    }
                    if (this.modalActiveIndex == this.modalType.create) {
                        passdata.type = '新增记录';
                    }
                    passdata.Pricedata.memberlv = this.mList;
                    if (this.modalActiveIndex == this.modalType.edit) {
                        passdata['type'] = '修改编辑';
                    }

                    //修改影片范围的组件模式
                    passdata.Pricedata['movie'] = this.options2
                        .filter((item) => this.model14.indexOf(item.value) > -1)
                        .map((item) => ({
                            movieCode: item.value,
                            movieName: item.label,
                        }));

                    let res = await PriceInfo(passdata);
                    if (res.errcode != 0) return;
                    this.handlePriceInfo('获取列表');
                    this.cancel();
                    this.modalEdit = false;
                } else {
                }
            });
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        init() {
            this.timeSelectValue = '';
            this.memberPriceSelectValue = '';
            this.baseSellPriceSelectValue = '';
            this.targetKeys1 = [];
            this.model14 = [];
        },
        /**
         * 新增
         */
        create() {
            this.init();
            this.formValidate = JSON.parse(JSON.stringify(this.formValidateCopy));
            this.modalActiveIndex = this.modalType.create;
            this.modalEdit = true;
        },
        /**
         * 查看
         */
        view(value) {
            this.modalActiveIndex = this.modalType.view;
            this.modalEdit = true;
            this.currentRowData = value;
            this.handlePriceInfo('获取详情');
        },
        /**
         * 弹窗编辑
         */
        edit(value) {
            this.modalActiveIndex = this.modalType.edit;
            this.modalEdit = true;
            this.currentRowData = value;
            console.log(this.currentRowData);
            this.handlePriceInfo('获取详情');
        },
        /**
         * 删除
         */
        remove(value) {
            this.currentRowData = value;
            this.handlePriceInfo('删除记录');
        },
        async ok() {
            this.handleSubmit('formValidate');
        },
        /**
         * 弹窗取消按钮 初始化表单
         */
        cancel() {
            this.modalEdit = false;
            this.handleReset('formValidate');
            this.handleReset('formPolice');
            this.mList = [];
        },
        /**
         * 获取表格信息
         */
        async handlePriceInfo(type) {
            this.memberPriceSelectValue = '';
            const { user } = JSON.parse(localStorage.getItem('选中品牌1'));
            let passdata = {
                type: type,
                modifier: user.name,
            };
            if (type == '获取详情') {
                passdata['id'] = this.currentRowData._id;
            }
            if (type == '删除记录') {
                passdata['id'] = this.currentRowData._id;
            }
            let res = await PriceInfo(passdata);
            if (res.errcode != 0) return;
            if (type == '获取列表') {
                res.data.title.push({
                    title: '操作',
                    key: 'operation',
                    slot: 'operation',
                    width: 200,
                });
                res.data.title.forEach((item, index) => {
                    if (item.key == 'infoV') {
                        item['slot'] = 'infoV';
                        item['width'] = 150;
                    }
                    if (item.key == 'longTerm') {
                        item['slot'] = 'longTerm';
                    }
                    if (item.key == 'enableStatus') {
                        item['slot'] = 'enableStatus';
                    }
                });
                this.tableData = res.data;
            }
            if (type == '获取详情') {
                this.timeSelectValue = '';
                const { time, baseSellPrice, memberPrice, movie } = res.data;
                if (time.length > 0) {
                    this.timeSelectValue = '自定义';
                } else {
                    this.timeSelectValue = '全时段范围';
                }
                if (!Number(memberPrice)) {
                    this.memberPriceSelectValue = memberPrice;
                }
                if (!isNaN(memberPrice)) {
                    this.memberPriceSelectValue = '自定义';
                }
                if (!Number(baseSellPrice)) {
                    this.baseSellPriceSelectValue = baseSellPrice;
                }
                this.targetKeys1 = [];
                for (let i = 0; i < movie.length; i++) {
                    for (let j = 0; j < this.data1.length; j++) {
                        if (movie[i].movieCode == this.data1[j].movieCode) {
                            this.targetKeys1.push(j + '');
                        }
                    }
                    this.data1.filter((item, index) => item.movieCode == this.data1[i]['movieCode']);
                }
                this.mList = res.data.memberlv;
                this.formValidate = res.data;
                //TODO 回显数据到影片范围页签下的全程组件
                this.model14 = res.data.movie.map((item) => item.movieCode);
                this.options2 = res.data.movie.map((item) => ({
                    label: item.movieName,
                    value: item.movieCode,
                }));
            }
            if (type == '删除记录') {
                this.handlePriceInfo('获取列表');
            }
        },
    },
};
</script>

<style></style>
